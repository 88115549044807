<template>
  <div>
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <!-- Table Top -->

          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries </label>
            </b-col>

            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/user/user-add">
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
        > -->

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="fetchUsers"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            {{ data.value }}
          </template>

          <!-- Column: Name -->
          <template #cell(surname)="data">
            {{ data.value }}
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <b-badge pill :variant="`light-primary`" class="text-capitalize">
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
              Active
            </b-badge>

            <b-badge v-if="data.value == 'P'" pill :variant="`light-danger`" class="text-capitalize">
              Passive
            </b-badge>

            <b-badge v-if="data.value == 'D'" pill :variant="`danger`" class="text-capitalize">
              Deleted
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'user-edit', params: { id: data.item.id } }" v-if="$Can('user_show')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'user-edit', params: { id: data.item.id } }" v-if="$Can('user_edit')" v-show="data.item.status != 'D'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteUser(data.item.id)" v-if="$Can('user_add')" v-show="data.item.status != 'D'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item  v-if="$Can('user_delete')">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <b-modal id="modal-userselect" cancel-variant="outline-secondary" ok-title="Delete" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Select user to transfer user's quotes">
      <b-form ref="selectUserRules">
        <b-form-group :state="selectUserState" invalid-feedback="User select required">
          <label for="selectUserState">Users:</label>
          <v-select style="width:100%;" v-model="selectedUserId" :taggable="true" :reduce="(val) => val.id" :options="dropDownUsers" label="title" :clearable="false"> </v-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import useUserList from './useUserList';
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import userStoreModule from '../userStoreModule';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import router from '@/router';
export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchUsers: [],
      dropDownUsers: [],

      filterOn: ['id', 'name', 'surname', 'email', 'role', 'status'],
      tableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'name', sortable: true, class: 'text-center' },
        { key: 'surname', sortable: true, class: 'text-center' },
        { key: 'email', sortable: true, class: 'text-center' },
        { key: 'role', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: false },
      ],
      perPage: localStorage.getItem('userShow') ?? 10,
      currentPage: 1,
      totalRows: 0,
      selectedUserId: null,
      deleteUserId: null,

      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('userShow', val);
      },
    },
  },

  methods: {
    onRowSelected(item) {
      router.push({ name: 'user-edit', params: { id: item[0].id } });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        id: this.deleteUserId,
        selectedUserId: this.selectedUserId,
      };

      this.modalOkSelect(data);
    },

    modalOkSelect(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-userselect');
      });

      this.formShow = true;

      this.loading = true;
      store
        .dispatch('user/deleteUser', data)
        .then((res) => {
          this.fetchUsers = res.data.data;
          this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetModal() {
      this.selectedUserId = null;
    },

    checkFormValidity() {
      const selectUserValid = this.selectUserState;

      return selectUserValid;
    },

    getUsers() {
      this.loading = true;
      store
        .dispatch('user/fetchUsers', [])
        .then((res) => {
          this.fetchUsers = res.data.data;

          this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUser(val) {
      this.deleteUserId = val;
      this.loading = true;
      store
        .dispatch('user/fetchDropDownUsers', this.deleteUserId)
        .then((res) => {
          this.dropDownUsers = res.data;

          this.loading = false;
          this.$bvModal.show('modal-userselect');
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.getUsers();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },

    selectUserState() {
      return this.selectedUserId != null && this.selectedUserId != '' ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
